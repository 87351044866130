@import url('https://fonts.googleapis.com/css?family=Anton&display=swap" rel="stylesheet');

#root {
  height: 100%;
  font-family: "Helvetica Neue", "Arial", "Hiragino Sans",
    "Hiragino Kaku Gothic ProN", "Meiryo", "sans-serif";
}

html {
  scroll-behavior: smooth;
  background-image: url("../common/img/grain-bg.jpeg");
  background-repeat: repeat;
  background-size: 15%;
}

@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: smooth;
  }
}

.scroll {
  cursor: pointer;
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 30px;
  right: -80px;
  border-radius: 100%;
  color: rgb(0, 0, 0);
  font-size: 20px;
  text-align: center;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
  transition: 300ms;
}

.scroll i {
  margin-top: 10px;
  text-shadow: 0 0 2px #fff;
}

.scroll:hover i {
  animation-name: rotate;
  animation-duration: 300ms;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes rotate {
  from {
    margin-top: 15px;
  }
  to {
    margin-top: 5px;
  }
}

.visible {
  right: 30px;
  transition: all 400ms;
  transform: rotate(360deg);
}
