@import url(https://fonts.googleapis.com/css?family=Anton&display=swap" rel="stylesheet);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN",
    "Hiragino Sans", Meiryo, sans-serif; */
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

#root {
  height: 100%;
  font-family: "Helvetica Neue", "Arial", "Hiragino Sans",
    "Hiragino Kaku Gothic ProN", "Meiryo", "sans-serif";
}

html {
  scroll-behavior: smooth;
  background-image: url(/static/media/grain-bg.446a890a.jpeg);
  background-repeat: repeat;
  background-size: 15%;
}

@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: smooth;
  }
}

.scroll {
  cursor: pointer;
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 30px;
  right: -80px;
  border-radius: 100%;
  color: rgb(0, 0, 0);
  font-size: 20px;
  text-align: center;
  -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
          filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
  transition: 300ms;
}

.scroll i {
  margin-top: 10px;
  text-shadow: 0 0 2px #fff;
}

.scroll:hover i {
  -webkit-animation-name: rotate;
          animation-name: rotate;
  -webkit-animation-duration: 300ms;
          animation-duration: 300ms;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
}

@-webkit-keyframes rotate {
  from {
    margin-top: 15px;
  }
  to {
    margin-top: 5px;
  }
}

@keyframes rotate {
  from {
    margin-top: 15px;
  }
  to {
    margin-top: 5px;
  }
}

.visible {
  right: 30px;
  transition: all 400ms;
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}

/* Disable Scroll */
ul {
  -ms-overflow-x-style: none;    /* IE, Edge */
  scrollbar-width: none;       /* Firefox */
  /* padding-inline-start: 10px; */
}

ul::-webkit-scrollbar {  /* Chrome, Safari */
  display:none;
}
